<template>
  <div class="event-gallery">
    <div class="live-container pa-5">
      <h2 class="text-md-h5 headline lazboy-conf-title font-weight-bold mb-4">
        La-Z-Boy New Product Launching 2022
      </h2>
      <div class="columns">
        <div class="column is-9">
          <video
            :key="vdoSource"
            class="live-vdo"
            controls
            preload="auto"
            :poster="poster"
          >
            <source :src="vdoSource" />
          </video>
        </div>
        <div class="column is-3" id="vdo-list-1">
          <div class="scroll">
            <div
              class="box"
              @click="
                selectVideo(
                  '/videos/2022/event-high-light.mp4',
                  require('@/assets/post_event/2022/event-hl.png')
                )
              "
            >
              <article class="media">
                <figure class="image">
                  <img
                    :src="require('@/assets/post_event/2022/event-hl.png')"
                  />
                </figure>
              </article>
            </div>
            <div
              class="box"
              @click="
                selectVideo(
                  '/videos/2022/Product-Highlight_Full.mp4',
                  require('@/assets/post_event/2022/product-hl-full.png')
                )
              "
            >
              <article class="media">
                <figure class="image">
                  <img
                    :src="
                      require('@/assets/post_event/2022/product-hl-full.png')
                    "
                  />
                </figure>
              </article>
            </div>
            <div
              class="box"
              @click="
                selectVideo(
                  '/videos/2022/Product-Highlight_Short.mp4',
                  require('@/assets/post_event/2022/product-hl-short.png')
                )
              "
            >
              <article class="media">
                <figure class="image">
                  <img
                    :src="
                      require('@/assets/post_event/2022/product-hl-short.png')
                    "
                  />
                </figure>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="live-container pa-5">
      <h2 class="text-md-h5 headline lazboy-conf-title font-weight-bold mb-4">
        La-Z-Boy Asia Virtual Conference 2020
      </h2>
      <div class="columns">
        <div class="column is-9">
          <video
            :key="vdoSource"
            class="live-vdo"
            controls
            preload="auto"
            :poster="poster"
          >
            <source :src="vdoSource" />
          </video>
        </div>
        <div class="column is-3" id="vdo-list-2">
          <div class="scroll">
            <div class="box" @click="eventHl()">
              <article class="media">
                <figure class="image">
                  <img :src="require('@/assets/post_event/event-hl.png')" />
                </figure>
              </article>
            </div>
            <div class="box" @click="pot()">
              <article class="media">
                <figure class="image">
                  <img :src="require('@/assets/post_event/pot.png')" />
                </figure>
              </article>
            </div>
            <div class="box" @click="bs()">
              <article class="media">
                <figure class="image">
                  <img :src="require('@/assets/post_event/bs.png')" />
                </figure>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  layout: "event-gallery",
  data: () => {
    return {
      playing: false,
      fetchLive: null,
      failToPlay: false,
      lang: "en",
      vdoSource: "/videos/2022/event-high-light.mp4",
      poster: require("@/assets/post_event/2022/event-hl.png"),
      vId: "vEn",
    };
  },
  methods: {
    selectVideo(src, poster) {
      this.vdoSource = src;
      this.poster = poster;
    },
    eventHl() {
      this.vdoSource = "/videos/highlight.mp4";
      this.poster = require("@/assets/post_event/event-hl.png");
    },
    pot() {
      this.vdoSource = "/videos/product-of-the-year.mp4";
      this.poster = require("@/assets/post_event/pot.png");
    },
    bs() {
      this.vdoSource = "/videos/behind-the-scense.mp4";
      this.poster = require("@/assets/post_event/bs.png");
    },
  },
  async mounted() {
    setTimeout(() => {
      const vdoContainerHeight =
        Number(document.getElementsByTagName("video")[0].clientHeight) + 22;
      document.getElementById(
        "vdo-list-1"
      ).style = `height: ${vdoContainerHeight}px`;
      document.getElementById(
        "vdo-list-2"
      ).style = `height: ${vdoContainerHeight}px`;
    }, 500);
  },
};
</script>
<style lang="scss">
@import "~vuetify/src/styles/styles.sass";
#vdo-list-1,
#vdo-list-2 {
  .scroll {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .box {
    cursor: pointer;
    background-color: black;
    padding: 0.25rem;
  }
}
.ma {
  margin: auto;
}
.live-actions {
  float: right;
}
.live-vdo {
  width: 100%;
  // min-height: 500px;

  @media #{map-get($display-breakpoints, 'md-only')} {
    min-height: 400px;
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    min-height: 250px;
  }
}

.event-gallery {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    // background-color: #002c56;
    margin: 0 100px;
  }
}

.live-container {
  min-height: 30%;
  @media screen and (min-width: 769px) {
    padding: 4rem;
  }
}
.lazboy-conf-title {
  font-family: "Helvetica Neue LT W05 65 Medium";
  color: #0e0e0e;
}
</style>
