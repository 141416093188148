var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"event-gallery"},[_c('div',{staticClass:"live-container pa-5"},[_c('h2',{staticClass:"text-md-h5 headline lazboy-conf-title font-weight-bold mb-4"},[_vm._v(" La-Z-Boy New Product Launching 2022 ")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-9"},[_c('video',{key:_vm.vdoSource,staticClass:"live-vdo",attrs:{"controls":"","preload":"auto","poster":_vm.poster}},[_c('source',{attrs:{"src":_vm.vdoSource}})])]),_c('div',{staticClass:"column is-3",attrs:{"id":"vdo-list-1"}},[_c('div',{staticClass:"scroll"},[_c('div',{staticClass:"box",on:{"click":function($event){_vm.selectVideo(
                '/videos/2022/event-high-light.mp4',
                require('@/assets/post_event/2022/event-hl.png')
              )}}},[_c('article',{staticClass:"media"},[_c('figure',{staticClass:"image"},[_c('img',{attrs:{"src":require('@/assets/post_event/2022/event-hl.png')}})])])]),_c('div',{staticClass:"box",on:{"click":function($event){_vm.selectVideo(
                '/videos/2022/Product-Highlight_Full.mp4',
                require('@/assets/post_event/2022/product-hl-full.png')
              )}}},[_c('article',{staticClass:"media"},[_c('figure',{staticClass:"image"},[_c('img',{attrs:{"src":require('@/assets/post_event/2022/product-hl-full.png')}})])])]),_c('div',{staticClass:"box",on:{"click":function($event){_vm.selectVideo(
                '/videos/2022/Product-Highlight_Short.mp4',
                require('@/assets/post_event/2022/product-hl-short.png')
              )}}},[_c('article',{staticClass:"media"},[_c('figure',{staticClass:"image"},[_c('img',{attrs:{"src":require('@/assets/post_event/2022/product-hl-short.png')}})])])])])])])]),_c('div',{staticClass:"live-container pa-5"},[_c('h2',{staticClass:"text-md-h5 headline lazboy-conf-title font-weight-bold mb-4"},[_vm._v(" La-Z-Boy Asia Virtual Conference 2020 ")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-9"},[_c('video',{key:_vm.vdoSource,staticClass:"live-vdo",attrs:{"controls":"","preload":"auto","poster":_vm.poster}},[_c('source',{attrs:{"src":_vm.vdoSource}})])]),_c('div',{staticClass:"column is-3",attrs:{"id":"vdo-list-2"}},[_c('div',{staticClass:"scroll"},[_c('div',{staticClass:"box",on:{"click":function($event){return _vm.eventHl()}}},[_c('article',{staticClass:"media"},[_c('figure',{staticClass:"image"},[_c('img',{attrs:{"src":require('@/assets/post_event/event-hl.png')}})])])]),_c('div',{staticClass:"box",on:{"click":function($event){return _vm.pot()}}},[_c('article',{staticClass:"media"},[_c('figure',{staticClass:"image"},[_c('img',{attrs:{"src":require('@/assets/post_event/pot.png')}})])])]),_c('div',{staticClass:"box",on:{"click":function($event){return _vm.bs()}}},[_c('article',{staticClass:"media"},[_c('figure',{staticClass:"image"},[_c('img',{attrs:{"src":require('@/assets/post_event/bs.png')}})])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }